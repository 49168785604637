<template>
  <el-container>
    <el-main>
      <div class="myform">
        <div class="logo_image">
          <el-image
            :src="require('../../public/iclimate.jpg')"
            fit="scale-down"
            style="width: 70vw; height: 40vh"
          ></el-image>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="psw">
            <el-input v-model="ruleForm.psw" type="password"></el-input>
          </el-form-item>

          <el-form-item>
            <el-row :span="24" :gutter="10">
              <el-col :span="4"><div class="space_holder"></div></el-col>
              <el-col :span="6">
                <el-button round size="medium" @click="resetForm('ruleForm')"
                  >重置</el-button
                >
              </el-col>
              <el-col :span="4"><div class="space_holder"></div></el-col>
              <el-col :span="6">
                <el-button round
                  type="primary"
                  size="medium"
                  @click="submitForm('ruleForm')"
                  >登录</el-button
                >
              </el-col>
              <el-col :span="4"><div class="space_holder"></div></el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
    </el-main>
    <el-footer>
      <div id="bottom_layer"  style="visibility: visible; width: 100%;">
        <div class="s-bottom-layer-content">
          <p class="lh">
            <a class="text-color" href="https://beian.miit.gov.cn">鄂ICP备2022003227号-1</a>
          </p>
          <p class="lh">
            <a class="text-color" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010602004673">鄂公网安备 42010602004673号</a>
          </p>
        </div>
      </div> 
    </el-footer>
  </el-container>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { accountLogin } from "../../axios/data";
var checkPsw = (rule, value, callback) => {
  //console.log(rule.max_age)
  if (!value) {
    return callback(new Error("密码不能为空"));
  }
  let regexp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/;
  if (!regexp.test(value)) {
    callback(
      new Error(
        "至少八个字符，至少一个大写字母，一个小写字母，一个数字和一个特殊字符:"
      )
    );
  } else {
    callback();
  }
};
export default {
  name: "login",
  data() {
    return {
      ruleForm: {
        name: "",
        psw: "",
      },
      rules: {
        name: [
          { required: false, message: "请输入用户名", trigger: "blur" },
          {
            min: 8,
            max: 20,
            message: "长度在 8 到 20 个字符",
            trigger: "blur",
          },
        ],
        psw: [{ required: false, validator: checkPsw, trigger: "blur" }],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.refreshStyle();
    }, 500);
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {};
          obj.usrname = this.ruleForm.name;
          obj.psw = this.ruleForm.psw;
          accountLogin(this.domain, obj)
            .then((res) => {
              if (res.data.status === "true") {
                this.$store.commit("setUSRName", res.data.message.usrname);
                this.$store.commit("setUSRPsw", res.data.message.psw);
                this.$store.commit("setCompany", res.data.message.company);
                this.$store.commit(
                  "setTitleList",
                  JSON.parse(res.data.message.titlelist)
                );
                const token = uuidv4();
                this.$store.commit("setToken", token);
                this.$router.push({ name: "normal" });
              } else {
                this.$message.warning("密码错误, 请重试");
              }
            })
            .catch((err) => {
              this.$message.warning(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    refreshStyle() {
      var MyForm = document.querySelector(".myform");
      MyForm.style.height = 80 + "vh";
      MyForm.style.marginBottom = 15 + "vh";
    },
  },
  computed: {
    domain() {
      return this.$store.state.tab.domain;
    },
    domain443() {
      return this.$store.state.tab.domain443;
    },
    windowWidth() {
      return this.$store.state.tab.windowWidth;
    },
    windowHeight() {
      return this.$store.state.tab.windowHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  background-image: url(../../public/loginbj.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.myform {
  transform: translateY(10vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo_image {
    .el-image {
      align-items: center;
      display: flex;
      justify-content: center;
      opacity: 0.9;
    }
    margin-bottom: 5vh;
  }
}
.myform ::v-deep {
  .el-form {
    width: 30vw;
    margin-left: -60px;
  }
  .el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: aqua;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
}

.space_holder {
  display: flex;
  font-size: 1.042vw;
  align-items: center;
  justify-content: center;
  min-height: 2.083vw;
  max-height: 2.083vw;
}

el-container {
  margin: 0;
  padding: 0;
}

el-header,
el-row,
el-col {
  margin: 0;
  padding: 0;
  
}

.el-input {
  // -webkit-appearance: none;

  border-radius: 5px;
  // border: 1px solid red;
  // background-color: #fff;
}

#bottom_layer {
    width: 3000px;
    position: fixed;
    z-index: 302;
    bottom: 10px;
    left: 0;
    height: 39px;
    padding-top: 1px;
    zoom: 1;
    margin: 0;
    line-height: 39px;
    background: #fff;
}

div {
  display: block;
}
#bottom_layer .s-bottom-layer-content {
    margin: 0 17px;
    text-align: center;
}

#bottom_layer .lh {
    display: inline-block;
    margin-right: 14px;
}

#bottom_layer .text-color {
    color: #bbb;
}
#bottom_layer a {
    font-size: 12px;
    text-decoration: none;
}
</style>